<template>
  <form @submit.prevent="submitForm">
    <div class="form-control" :class="{ invalid: submitted && firstNameInvalid }">
      <label for="firstname">Firstname</label>
      <input type="text" id="firstname" v-model.trim="firstName" />
      <p v-if="submitted && firstNameInvalid">Firstname must not be empty.</p>
    </div>
    <div class="form-control" :class="{ invalid: submitted && lastNameInvalid }">
      <label for="lastname">Lastname</label>
      <input type="text" id="lastname" v-model.trim="lastName" />
      <p v-if="submitted && lastNameInvalid">Lastname must not be empty.</p>
    </div>
    <div class="form-control" :class="{ invalid: submitted && descriptionInvalid }">
      <label for="description">Description</label>
      <textarea id="description" rows="5" v-model.trim="description"></textarea>
      <p v-if="submitted && descriptionInvalid">Description must not be empty.</p>
    </div>
    <div class="form-control" :class="{ invalid: submitted && rateInvalid }">
      <label for="rate">Hourly Rate</label>
      <input type="number" id="rate" v-model.number="rate" />
      <p v-if="submitted && rateInvalid">Rate must be greater than 0.</p>
    </div>
    <div class="form-control" :class="{ invalid: submitted && areasInvalid }">
      <h3>Areas of Expertise</h3>
      <div>
        <input type="checkbox" id="frontend" value="frontend" v-model="areas" />
        <label for="frontend">Frontend Development</label>
      </div>
      <div>
        <input type="checkbox" id="backend" value="backend" v-model="areas" />
        <label for="backend">Backend Development</label>
      </div>
      <div>
        <input type="checkbox" id="career" value="career" v-model="areas" />
        <label for="career">Career Advisory</label>
      </div>
      <p v-if="submitted && areasInvalid">At least one expertise must be selected.</p>
    </div>
    <p v-if="submitted && formInvalid">Please fix the above errors and submit again.</p>
    <base-button>Register</base-button>
  </form>
</template>

<script>
export default {
  emits: ["save-data"],
  data() {
    return {
      submitted: false,
      firstName: "",
      lastName: "",
      description: "",
      rate: null,
      areas: [],
    };
  },
  computed: {
    firstNameInvalid() {
      return this.firstName === "";
    },
    lastNameInvalid() {
      return this.lastName === "";
    },
    descriptionInvalid() {
      return this.description === "";
    },
    rateInvalid() {
      return !this.rate || this.rate < 0;
    },
    areasInvalid() {
      return this.areas.length === 0;
    },
    formInvalid() {
      return (
        this.firstNameInvalid ||
        this.lastNameInvalid ||
        this.descriptionInvalid ||
        this.rateInvalid ||
        this.areasInvalid
      );
    },
  },
  methods: {
    submitForm() {
      this.submitted = true;

      if (this.formInvalid) {
        return;
      }

      const formData = {
        first: this.firstName,
        last: this.lastName,
        desc: this.description,
        rate: this.rate,
        areas: this.areas,
      };

      this.$emit("save-data", formData);
    },
  },
};
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type="checkbox"] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}

input[type="checkbox"]:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>
